<template>
  <p
    class="work flex"
    @click.stop="handleWorkplace(cType, cId, cStatus, cItem, cIndex)"
    title="点击取消"
    :style="{ fontSize: fontSize + 'px' }"
  >
    <img
      :style="{ transform: 'scale(' + multiples + ')' }"
      src="@/assets/img/common/workplace-act.png"
      alt=""
    />
    <span>发布</span>
  </p>
</template>
<script>
export default {
  props: {
    cType: "",
    cId: "",
    cStatus: "",
    cIndex: "",
    cItem: "",
    fontSize: {
      type: Number || String,
      default: 16,
    },
  },
  data() {
    return {
      multiples: 1.3,
    };
  },
  created() {
    let _self = this;
    setTimeout(() => {
      _self.multiples = 1;
    }, 200);
  },
  methods: {
    // 发布到工作台
    handleWorkplace(type, value, status, item, index) {
      let _self = this;
      _self.$emit("workbenchCancel", status, index, item);
      _self.axios
        .post(
          config.url.workCancel,
          _self.$qs.stringify({
            type: type,
            project_id: value,
          })
        )
        .then((res) => {
          if (res.code == 200) {
            res.tip == 1 ? _self.showTip("success", res.msg) : "";
          } else if (res.code != 200 && res.code != 401) {
            _self.showTip("error", res.msg);
          }
        });
    },
  },
};
</script>
<style scoped lang="scss">
.work {
  cursor: pointer;
  img {
    margin-right: 10px;
    display: inline-block;
    width: 18px;
    height: 18px;
    object-fit: contain;
    // transform: scale(2);
  }
}
</style>
