import Vue from "vue";
import App from "./App.vue";
import router from "./router";

//引入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 引入swiper
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';

//引入config
import config from '@/request/config.js';
global.config = config;
import '@/axios.js';

//引入qs 增加了一些安全性的查询字符串解析和序列化字符串的库
import qs from 'qs'; 
Vue.prototype.$qs = qs;
// 引入iconfont
import '@/assets/iconfont/iconfont.css';
import BaseFavor from '@/components/favor/BaseFavor.vue'
import CancelFavor from '@/components/favor/CancelFavor.vue'
import CourseFavor from '@/components/favor/CourseFavor.vue'
import CourseCancelFavor from '@/components/favor/CourseCancelFavor.vue'
import Workbench from '@/components/workbench/Workbench.vue'
import WorkbenchCancel from '@/components/workbench/WorkbenchCancel.vue'
import BaseCopyright from '@/components/copyright/BaseCopyright.vue'

//定义全局提示
Vue.prototype.showTip = function(type, msg, time){
  this.$message({
    message: msg,
    type: type ,
    duration: time || 1000,
    offset: 310,
    customClass: 'message-class'
  })
}

Vue.prototype.setOriginPath = function(){
  localStorage.setItem('originPath', this.$route.path);
}
Vue.component('BaseFavor',BaseFavor)
Vue.component('CancelFavor',CancelFavor)
Vue.component('CourseFavor',CourseFavor)
Vue.component('CourseCancelFavor',CourseCancelFavor)
Vue.component('Workbench',Workbench)
Vue.component('WorkbenchCancel',WorkbenchCancel)
Vue.component('BaseCopyright', BaseCopyright)

// 在npm run serve 和 npm run build时的提示
Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.use(VueAwesomeSwiper);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
