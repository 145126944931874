'use strict';

import Vue from 'vue';
import axios from 'axios';
import router from './router';
import { Message } from 'element-ui';
// import qs from 'qs';
// Vue.prototype.$qs = qs;
// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';

// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.headers.common['token'] = localStorage.getItem('token');
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);
// 添加请求拦截器
_axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      // 判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers.token = token;
    }
    return config;
  },
  function(error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  },
);

// 添加响应拦截器

_axios.interceptors.response.use(
  function(response) {
    //如果token失效，刷新页面
    if (response.data.code == 401) {
       //清除token，再重新获取token       
      localStorage.removeItem('token');
      router.replace('/');
      Message({
        message: response.data.msg,
        type: 'error',
        offset: 300
      })
    }
    return response.data;
  },
  function(error) {
    // 对响应错误做点什么
	// localStorage.removeItem('token');
  //  //   重新去获取token
  //  router.replace({
  //   path: '/'
  // })
    
    return Promise.reject(error);
  },
);
Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
