<template>
  <span
    class="favor flex"
    @click.stop="handleFavour(cId, cStatus, cIndex, cItem)"
    title="点击取消"
    :style="{ fontSize: fontSize + 'px' }"
  >
    <img
      :style="{ transform: 'scale(' + multiples + ')' }"
      src="@/assets/img/common/favor-act.png"
      alt=""
    />
    收藏
  </span>
</template>
<script>
export default {
  props: {
    cId: "",
    cStatus: "",
    cIndex: "",
    cItem: "",
    fontSize: {
      type: Number || String,
      default: 16,
    },
  },
  data() {
    return {
      multiples: 1.2,
    };
  },
  created() {
    let _self = this;
    setTimeout(() => {
      _self.multiples = 1;
    }, 300);
  },
  methods: {
    handleFavour(id, status, index = "", item = "") {
      let _self = this;
      _self.$emit("favorCancel", status, index, item);
      _self.axios
        .post(
          config.url.gameFavor,
          _self.$qs.stringify({
            game_id: id,
            type: status,
          })
        )
        .then((res) => {
          if (res.code == 200) {
            res.tip == 1 ? _self.showTip("success", res.msg) : "";
          } else if (res.code != 200 && res.code != 401) {
            _self.showTip("error", res.msg);
          }
        });
    },
  },
};
</script>
<style scoped lang="scss">
.favor {
  cursor: pointer;
  margin-top: -3px;
  img {
    width: 18px;
    height: 18px;
    margin-right: 6px;
  }
}
</style>
