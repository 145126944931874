<template>
  <div class="copyright">
    Copyright © {{ copyright.year }} {{ copyright.web_name }} 版权所有
    网站备案号：<span  @click="toCopyright">{{ copyright.beian }}</span>
    
  </div>
</template>
<script>
export default {
  data() {
    return {
      copyright: {},
    };
  },
  created() {
    let _self = this;
    _self.initData();
  },
  methods: {
    initData() {
      let _self = this;
      _self.axios.post(config.url.common).then((res) => {
        if (res.code == 200) {
          _self.copyright = res.data;
          localStorage.setItem('webTitle', res.data.web_name);
          localStorage.setItem('bbsUrl',res.data.bbs_url)
        } else if (res.code != 200 && res.code != 401) {
          _self.showTip("error", res.msg);
        }
      });
    },
    toCopyright() {
      window.open(this.copyright.beian_url, "_blank");
    },
  },
};
</script>
<style scoped lang="scss">
// 版权
.copyright {
  cursor: default;
  width: 100%;
  text-align: center;
  color: $font-color-81;
  padding: 30px 0;
  span{
    cursor: pointer;
  }
}
</style>
