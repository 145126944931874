import Vue from "vue";
import VueRouter from "vue-router";
//引入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: "/",
    name: "",
    component: () => import("@/views/BaseHome.vue"),
    redirect: '/',
    children:[// 1.注册
      {
        path: "",
        name: "home",
        component: () => import( "@/views/MainHome.vue")
      },
      {
        path: '/games',
        redirect: '/games',
        component: () => import('@/views/games/BaseGame.vue'),
        meta:{
          title: '游戏'
        },
        children:[
          {
            path: "/games",
            name: 'game',
            component: () => import('@/views/games/MainGame.vue'),
            meta:{
              title: '游戏'
            },
          },
          {
            path: "/games/game-info/:id",
            name: 'game-info',
            component: () => import('@/views/games/GameInfo.vue'),
            meta:{
              title: '游戏详情'
            },
          },
          {
            path: "/games/game-create/:id",
            name: 'game-create',
            component: () => import('@/views/games/GameCreate.vue'),
            meta:{
              title: '创建游戏'
            },
          },
          {
            path: "/games/game-create1",
            name: 'game-create1',
            component: () => import('@/views/games/GameCreate1.vue'),
            meta:{
              title: '创建游戏'
            },
          },
        ]
      },
      // 课件
      {
        path: '/course',
        redirect: '/course',
        component: () => import('@/views/course/MainCourse.vue'),
        meta:{
          title: '课件'
        },
        children:[
          {
            path: '/course',
            name: 'course',
            component: () => import('@/views/course/BaseCourse.vue'),
            meta:{
              title: '课件'
            },
          },
          {
            path: '/course/course-info/:courseId',
            name: 'course-info',
            component: () => import('@/views/course/CourseInfo.vue'),
            meta:{
              title: '课件详情'
            },
          },{
            path: '/course/level-info/:levelId',
            name: 'level-info',
            component: () => import('@/views/course/LevelInfo.vue'),
            meta:{
              title: '课程详情'
            },
          },
          {
            path: '/course/lesson-info/:lessonId',
            name: 'lesson-info',
            component: () => import('@/views/course/LessonInfo.vue'),
            meta:{
              title: '章节详情'
            },
          },
        ]
      },
      // 个人中心
      {
        path: '/profile',
        redirect: '/profile/workplace',
        component: () => import('@/views/profile/MainProfile.vue'),
        meta:{
          title: '个人中心'
        },
        children:[
          {
            path: '/profile/order',
            name: 'myorder',
            component: () => import('@/views/profile/BaseOrder.vue'),
            meta:{
              title: '我的订单',
              idx: 4
            },
          },
          {
            path: '/profile/course',
            name: 'bought-course',
            component: () => import('@/views/profile/BaseCourse.vue'),
            meta:{
              title: '已购课件',
              idx: 2
            },
          },
          {
            path: '/profile/game',
            name: 'my-game',
            component: () => import('@/views/profile/BaseGame.vue'),
            meta:{
              title: '我的游戏',
              idx: 1
            },
          },
          {
            path: '/profile/buyGame',
            name: 'buy-game',
            component: () => import('@/views/profile/BuyGame.vue'),
            meta:{
              title: '我的游戏',
              idx: 1
            },
          },
          {
            path: '/profile/favor',
            name: 'my-favor',
            component: () => import('@/views/profile/BaseFavor.vue'),
            meta:{
              title: '我的收藏',
              idx: 3
            },
          },
          {
            path: '/profile/gameFavor',
            name: 'game-favor',
            component: () => import('@/views/profile/GameFavor.vue'),
            meta:{
              title: '我的收藏',
              idx: 3
            },
          },
          {
            path: '/profile/workplace',
            name: 'my-workplace',
            component: () => import('@/views/profile/BaseWorkplace.vue'),
            meta:{
              title: '我的工作台',
              idx: 0
            },
          },
          {
            path: '/profile/gameWorkplace',
            name: 'game-workplace',
            component: () => import('@/views/profile/GameWorkplace.vue'),
            meta:{
              title: '我的工作台',
              idx: 0
            },
          },
          {
            path: '/profile/baseCate',
            name: 'my-cate',
            component: () => import('@/views/profile/BaseCate.vue'),
            meta:{
              title: '我的分类',
              idx: 5
            },
          },
        ]
      },
      {
        path: 'purchase/:id/:type',
        name: 'purchase',
        component: () => import('@/views/common/BasePurchase.vue'),
        meta:{
          title: '购买'
        },
      },
    ]
  },
  // 游戏
  {
    path: '/games/base-game1/:gameId?/:configId?',
    name: 'base-game1',
    component: () => import('@/views/games/BaseGame1.vue'),
    meta:{
      title: 'Hidden Pictures Game'
    },
  },
  {
    path: '/games/base-game2/:gameId?/:configId?',
    name: 'base-game2',
    component: () => import('@/views/games/BaseGame2.vue'),
    meta:{
      title: "what's Missing"
    },
  },
  {
    path: '/games/base-game3/:gameId?/:configId?',
    name: 'base-game3',
    component: () => import('@/views/games/BaseGame3.vue'),
    meta:{
      title: '幸运大转盘'
    },
  },
  {
    path: '/games/base-game4/:gameId?/:configId?',
    name: 'base-game4',
    component: () => import('@/views/games/BaseGame4.vue'),
    meta:{
      title: '滑块游戏'
    },
  },
  {
    path: '/login',
    name: 'wechat-login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/404',
    name: 'notFound',
    component:() => import('@/views/expection/404.vue')
  },
  {
    path: '*', //此处需特别注意置于最底部
    redirect: '/404'
  }
];

const router = new VueRouter({
  routes
});
// 没有token的情况下只能进入首页
router.beforeEach((to,from,next)=>{
  const webTitle = localStorage.getItem('webTitle');
  let _webTitle = webTitle ?  webTitle : '砸壳教育';
  window.document.title = to.meta.title == undefined ? _webTitle : to.meta.title;
  // 免登录页面
  let _routers = ['/login','/','/course', '/game', '/profile'];
  let token = localStorage.getItem('token');
  if(_routers.indexOf(to.path) == -1){
    if(!token){
      //不是免登录，没有token
      ElementUI.Message({
         message: '请先登录',
         type: 'warning'
       }); 
       next('/')
    }else{
      //不是免登录，有token
      next();
    }
  }else{
    //免登录
    next();
    
  }
})


export default router;
