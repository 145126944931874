// const src = 'http://zake.seenfine.net';
// const src = 'http://www.xlystny.cn';
// const src = 'http://192.168.0.88';
// const src = 'http://' + window.location.host;       
// const baseUrl = src + '/index.php';
const mode = process.env.NODE_ENV;
const location = window.location;
let baseIp = ''
let src = ''
if(mode === 'development') {
  baseIp = 'http://www.zakeabc.com'
  src = 'http://www.zakeabc.com'
} else {
  // let port = location.port ? `:${location.port}` : ''
  baseIp = `${location.protocol}//${location.hostname}`
  src = `${location.protocol}//${location.hostname}`
}
var baseUrl = baseIp + '/index.php'
module.exports = {
	src: src,
	baseUrl,
	url: {
		//公共
		getToken: baseUrl + '/Login/getToken',
    getCodePic: baseUrl + '/Login/createMpLoginQrCode',//获取二维码
    getCodeResult: baseUrl + '/Login/getLoginResult',//获取扫码结果
    mpLoginSMS: baseUrl + '/Login/mpLoginSMS',//发送短信验证码
    bindPhoneV2: baseUrl + '/Login/bindPhoneV2',//绑定手机号码
		uploadPic: baseUrl + '/Base/uploadPic',//上传图片
		payResult: baseUrl + '/Order/getPayResult',//订单查询
		orderTimeout: baseUrl + '/Order/markOrderCancel',//支付超时订单失效
		common: baseUrl + '/Config/base',//公共
		// 首页
		register: baseUrl + '/Login/register',//注册
		login: baseUrl + '/Login/login',//注册
		phoneCode: baseUrl + '/Login/registerSMS', //手机号验证码
		forgetPhoneCode: baseUrl + '/Login/forgetPwdSMS', //忘记密码手机号验证码
		forgetSubmit: baseUrl + '/Login/forgetPwd', //忘记密码提交
		home: baseUrl + '/Index/index', //首页
		userInfo: baseUrl + '/User/info', //首页个人信息		
        
		//游戏
		gameList: baseUrl + '/Game/list', //游戏列表
		gameInfo: baseUrl + '/Game/info', //游戏详情
		gameFavor: baseUrl + '/Game/collect', //游戏收藏
		gamePics: baseUrl + '/Game/myConfig', //获取用户上传的配置
		saveConfig: baseUrl + '/Game/saveConfig', //保存配置
		gameDelete: baseUrl + '/Game/deleteConfig', //删除配置
    // 游戏分类
    getListOfPage: baseUrl + '/Game/getListOfPage', //游戏分类（分页）
    delCate: baseUrl + '/Game/deleteCate', //删除游戏分类
    getListByID: baseUrl + '/Game/getListByID', //获取下级分类列表
    treeCate: baseUrl + '/Game/treeCate', //游戏分类(树形)
    insertCate: baseUrl + '/Game/insertCate', //创建分类
    editCate: baseUrl + '/Game/editCate', //修改分类
    moveConfigCate: baseUrl + '/Game/moveConfigCate', //移动游戏分类

		//课件
		courseList: baseUrl + '/Course/list', //课程列表
		courseInfo: baseUrl + '/Course/index', //课程详情
		levelList: baseUrl + '/Course/getLevelList', //课件列表
		levelInfo: baseUrl + '/Course/level', //level详情
		lessonInfo: baseUrl + '/Course/lesson', //lesson详情
		coursePreview: baseUrl + '/Course/preview', //课件预览

		// 订单
		orderCreate: baseUrl + '/Order/createOrder', //lesson详情
		orderCancel: baseUrl + '/Order/cancelOrder', //lesson详情

		// 个人中心
		orderList: baseUrl + '/Order/list', //订单列表
		myCourse: baseUrl + '/Course/my', //我的课件
		myGame: baseUrl + '/Game/my', //我的游戏
		myFavour: baseUrl + '/User/collect', //我的收藏
		myFavourDel: baseUrl + '/User/cancelCollect', //取消我的收藏
		myWorkplace: baseUrl + '/Workbench/list', //我的工作台
		myWorkplaceDel: baseUrl + '/Workbench/cancelIssue', //我的工作台删除

		courseFavour: baseUrl + '/Course/collect', //收藏(课程，level，课件)
		workAdd: baseUrl + '/Workbench/add', //发布到工作台
		workCancel: baseUrl + '/Workbench/cancelIssue2', //取消发布到工作台
		getAccount: baseUrl + '/User/getBBSAccount', //获取用户账号，以及检验token是否过期
	},
  wechatLogin: 'http://www.zakeabc.com/index.php/Login/wechatPCLoginPage'
}
